import { createRouter, createWebHistory } from "vue-router";
import Training from "./components/Training.vue"
import Development from "./components/Development.vue"
import About from "./components/About.vue"
import Home from "./components/Home.vue"

const router = createRouter({
    history: createWebHistory(),
    routes: [
      { path: '/', redirect: '/home' },
      {
        path:'/home', component:Home
      },
      {
      path:'/training', component:Training
    },
    {
      path:'/development', component:Development
    },
    {
      path:'/about', component:About
    },
  ],
  linkActiveClass: 'active',
  });

  export default router;