<template>
  <the-navigation></the-navigation>
  <main>
    <router-view></router-view>
  </main>
  <footer>
    <router-view name="footer"></router-view>
  </footer>
</template>

<script>
import TheNavigation from './components/TheNavigation.vue';
export default {
  components: {
    TheNavigation
  },
}
</script>

<style>

</style>
