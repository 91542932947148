<template>
    <router-link to="/training">
    <img class="banner" alt="training" src="../assets/training.png">
    </router-link>
    <router-link to="/development">
    <img class="banner" alt="development" src="../assets/development.png">
    </router-link>
    <router-link to="/about">
    <img class="banner" alt="about" src="../assets/about.png">
    </router-link>

</template>

<script>
export default {

}
</script>

<style scoped>
    .banner{
        width:100%
    }
</style>