<template>
    <div class="heading">Training</div>
    <div class="body">
      <div>
        We geven training over verschillende onderwerpen.
        <br />Hieronder vindt U voorbeelden van trainingen die wij op dit moment
        geven. <br />Eerst enkele opmerkingen die studenten over onze trainingen
        hebben gegeven. <br />"Uitstekendedocent, uitstekend cursus materiaal,
        zeer goed reference materiaal. <br />"Erg duidelijk, perfecte opdeling
        tussen essentie en detail" <br />"Een docent die weetwaar hij het over
        heeft en die ook in staat is dit over te brengen" <br />"Zeer goede
        docent". De volgende trainingen worden door ons gegeven:
        <ul>
          <li>Software development for iOS (3 dagen)</li>
          <li>Software development for Android (3 dagen)</li>
          <li>The C programming language (3 dagen)</li>
          <li>The C programming language advanced (3 dagen)</li>
          <li>The C++ programming language (3 dagen)</li>
          <li>The C++ programming language advanced (3 dagen)</li>
          <li>The Java programming language (3 dagen)</li>
          <li>The Java programming language advanced (3 dagen)</li>
          <li>Design patterns (3 dagen)</li>
          <li>Object oriented analysis and design (4 dagen)</li>
          <li>Software development for embedded systems (4 dagen)</li>
          <li>UML 2.4 Complete (3 dagen)</li>
          <li>Qt application development (3 dagen)</li>
          <li>Python Basis (3 dagen)</li>
          <li>Python Advanced (3 dagen)</li>
          <li>Python for data analysis (3 dagen)</li>
          <li>Django, the Python based web framework (3 dagen)</li>
        </ul>
        Als U geinteresseerd bent in een inhouse training op een van
        bovengenoemde gebieden neem dan contact met ons op voor meer details en
        een vrijblijvende prijs opgave.
      </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.heading {
  height: 5rem;
  width: 100%;
  background-color: #1a037e;
  color: #f1a80a;
  display: flex;
  font-size: 3rem;
  align-items: center;
  justify-content: center;
}
.body {
  padding:5rem;
  background-color: #1a037e;
  color: #f1a80a;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
