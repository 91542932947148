<template>
  <header>
    <router-link class="link" to="/home">Excelbian</router-link>
  </header>
</template>

<style scoped>
header {
  width: 100%;
  height: 6rem;
  background-color: #11005c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: #f1a80a;
  font-size:400%;
}
</style>
