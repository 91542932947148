<template>
    <div class="heading">Software Ontwikkeling</div>
    <div class="body">
      <div>
        Excelbian.com ontwikkelt apps voor smartphones, web sites en server applicaties.
        Gebruik wordt gemaakt van Swift, Kotlin, Django of Vue en Node.js.
        <ul>
          <li>iPhone applicaties</li>
          <li>Android applicaties</li>
          <li>Django Web applicaties</li>
          <li>Vue Web applicaties</li>
          <li>Server applicaties</li>
        </ul>
        Als U geinteresseerd om door ons een applicatie te laten ontwikkelen neem dan contact op voor een vrijblijvend gesprek.
      </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.heading {
  height: 5rem;
  width: 100%;
  background-color: #1a037e;
  color: #f1a80a;
  display: flex;
  font-size: 3rem;
  align-items: center;
  justify-content: center;
}
.body {
  padding:5rem;
  background-color: #1a037e;
  color: #f1a80a;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>