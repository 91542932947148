import { createApp } from "vue";
import App from "./App.vue";
import router from './router.js';

// import MainPage from "./components/MainPage.vue";
// import NavBar from "./components/NavBar.vue";




const app = createApp(App);

app.use(router)

// app.component("main-page", MainPage);
// app.component("nav-bar", NavBar);

app.mount("#app");
