<template>
  <div class="heading">Over ons</div>
  <div class="subheading">Contact informatie</div>
  <div class="body">
    <div>
      <ul>
        <li>Telefoon +31356942550</li>
        <li>Mobiele telefoon +31622384807</li>
        <li>Mail info@excelbian.com</li>
        <li>Adres Verdilaan 15 1411HV Naarden</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.heading {
  height: 5rem;
  width: 100%;
  background-color: #1a037e;
  color: #f1a80a;
  display: flex;
  font-size: 3rem;
  align-items: center;
  justify-content: center;
}
.subheading {
  height: 5rem;
  width: 100%;
  background-color: #1a037e;
  color: #f1a80a;
  display: flex;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
}
.body {
  padding-top: 1rem;
  padding-bottom:5rem;
  padding-left:5rem;
  padding-right:5rem;
  background-color: #1a037e;
  color: #f1a80a;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
